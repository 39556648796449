import React from "react"
import { Container } from "theme-ui"
const Layout = ({children}) => (
    <Container
        pl={[20, 20, 20, 20, 300]}
        pr={[20, 20, 20, 20, 300]}
        sx= {{
        	padding: '5em'
        }}
    >
        Mica 404 - page not found. <a href='https://micaroe.com'>Click here</a>
    </Container>
)

export default Layout;